import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { AddOutlined, EditOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appRoute from "../../Route/appRoutes";
import http from "../../api";
import EnhancedTableHead from "../../component/table/EnhancedTableHead";
import {
  getComparator,
  showErrorMessage,
  stableSort,
} from "../../component/table/function";
import "./endpoint.css";

function Endpoints() {
  const closeSidebar = useSelector((state) => state.reducer.closeSidebar);
  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("endpoint_name");
  const [endpoints, setEndpoints] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [apiLoading, setApiLoading] = useState(true);

  const getEndpoints = () => {
    const body = {
      SearchText: searchTerm.trim(),
      PageSize: rowsPerPage,
      CurrentPage: page + 1,
      // "EndpointId": null,
      // "ActivationStatus": null,
      // "Organization": null,
      SortOrder: order,
      SortCol: orderBy,
    };

    http
      .post(`api/v1/Integration/EndpointMaster/Get`, body)
      .then((res) => {
        setTotalRecords(res?.RecordCount ?? 0);
        setEndpoints(res?.Value ?? []);
        setApiLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessage("Something went wrong");
        setApiLoading(false);
      });
  };

  useEffect(() => {
    getEndpoints();
  }, [searchTerm, rowsPerPage, page, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (apiLoading) {
    return null;
  }

  const headCells = [
    { id: "endpoint_name", label: "Endpoint", sortable: true },
    { id: "endpoint_type", label: "Type", sortable: true },
  ];

  return (
    <div
      className={`mainSection integrationComponent ${
        closeSidebar ? "maxMainSection" : "minMainSection"
      }`}
    >
      <div className="mainSectionHeading withAddnew">
        <span>Endpoints</span>
        <Button
          variant="contained"
          onClick={() => {
            history.push(appRoute.AddEndpoint);
          }}
          endIcon={<AddOutlined />}
        >
          Add Endpoint
        </Button>
      </div>
      <div className="tableParent">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <TextField
                label="Search"
                variant="standard"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ width: "300px", float: "right" }}
              />
            </div>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader>
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              action={true}
            />
            <TableBody>
              {endpoints.length ? (
                stableSort(endpoints, getComparator(order, orderBy))
                  // endpoints
                  .map((row, index) => {
                    let count = index + 1 + 1 * (page * rowsPerPage);
                    return (
                      <TableRow hover key={count}>
                        <TableCell align="center">{count}</TableCell>
                        <TableCell title={row.endpoint_name}>
                          {row.endpoint_name}
                        </TableCell>
                        <TableCell title={row.endpoint_type_name}>
                          {row.endpoint_type_name}
                        </TableCell>
                        <TableCell title="Edit">
                          <IconButton
                            color="primary"
                            aria-label="Edit Integration"
                            size="small"
                            onClick={() => {
                              history.push(
                                `/endpoints/edit/${row.endpoint_id}`
                              );
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align={"center"}>
                    No Master Endpoint found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default Endpoints;
