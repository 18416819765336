import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { alpha, styled } from "@material-ui/core/styles";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appRoute from "../../Route/appRoutes";
import { closeSidebarAction } from "../../Store/ReduxSlice";
import { ProjectName } from "../../config";
import "./header.css";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const closeSidebar = useSelector((state) => state.reducer.closeSidebar);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    handleClose();
    localStorage.clear();
    history.push("/");
    // window.location.reload();
  };

  const updatePassword = () => {
    handleClose();
    history.push(appRoute.CHANGEPASS);
  };

  return (
    <div className="headerComponent">
      <div className="menubar_Logo">
        <MenuIcon
          className="menuBar"
          // onClick={() => context.setCloseSidebar(!context.closeSidebar)}
          onClick={() => dispatch(closeSidebarAction(!closeSidebar))}
        />
        <img
          src={`${ProjectName}/Assets/Logo.svg`}
          className="headerLogo pointer"
          alt="logo"
          onClick={() => history.push(appRoute.INTEGRATION)}
        />
      </div>
      <div className="search_User">
        {/* <div className="headersearch">
                    <input value={search} type="text" className="form-control" id="headersearch"
                        onChange={(event) => { setSearch(event.target.value) }}
                        placeholder="Search" />
                    <img src="/charlesKendall/Assets/searchIcon.svg" alt="search" onClick={() => { }} />
                    {
                        search && <CloseIcon className='searchClearIcon' onClick={() => setSearch('')} />
                    }
                </div> */}
        <div className="user_notify">
          <AccountCircleRoundedIcon
            onClick={handleClick}
            className="userIcon"
          />
          <StyledMenu
            id="mailMenu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={updatePassword}>Change Password</MenuItem>
            <MenuItem onClick={logOut}>Log Out</MenuItem>
          </StyledMenu>
        </div>
      </div>
    </div>
  );
}

export default Header;
