import React, { useState, useEffect } from 'react';
import axios from 'axios';



const AddBranch = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [formData, setFormData] = useState({
    CW1: '',
    Dominio: '',
    CW1Name: '',
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        'https://asprod.sflhub.com/MiddlewareAppsAPI/ContabilMappingForBranch',
        {
          From: 0,
          To: 100000000,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      setData(response.data.Data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Insert

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await axios.post(
        'https://asprod.sflhub.com/MiddlewareAppsAPI/AddDetailsToContabilBranchTable',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      console.log('Data added successfully:', response.data.Data);
      fetchData()
      setData(prevData => [...prevData, response.data.Data]); 
      alert('Data added successfully')
      // You might want to update your data state with the new data if needed.

      // Clear the form after submission
      setFormData({
        CW1: '',
        Dominio: '',
        CW1Name: '',
      });
    } catch (error) {
      console.error('Error adding data:', error);
    }
  };
  // Delete
  const handleDelete = async (id) => {
    try {
      const response = await axios.post(
        'https://asprod.sflhub.com/MiddlewareAppsAPI/DeleteInBranch',
        {
          ID: id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      console.log('Data deleted successfully:', response.data);
      // You might want to update your data state after deletion if needed.
      setData((prevData) => prevData.filter(item => item.ID !== id));
      alert('Data deleted successfully:');
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

    // Update
    const [updateData, setUpdateData] = useState({
      ID: '',
      NewCW1: '',
      NewDominio: '',
      NewCW1Name: '',
    });
  
    const handleEditClick = (item) => {
      setUpdateData({
        ID: item.ID,
        NewCW1: item.CW1,
        NewDominio: item.Dominio,
        NewCW1Name: item.CW1Name,
      });
    };
    const handleUpdateInputChange = (event) => {
      const { name, value } = event.target;
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    
  
    const handleUpdateSubmit = async (id) => {
      try {
        const response = await axios.post(
          'https://asprod.sflhub.com/MiddlewareAppsAPI/EditInBranch',
          {
            ID: id,
            NewCW1: updateData.NewCW1,
            NewDominio: updateData.NewDominio,
            NewCW1Name:updateData.NewCW1Name
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        );
    
        console.log('Data updated successfully:', response.data);
        alert('Data updated successfully');
        setUpdateData({
          ID: '',
          NewCW1: '',
          NewDominio: '',
          NewCW1Name:''
        });
        fetchData(); // Refresh the data after update
      } catch (error) {
        console.error('Error updating data:', error);
      }
    };
   
  

     // ... other functions ...

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        'https://asprod.sflhub.com/MiddlewareAppsAPI/SearchInContabilMapping',
        {
          Search: searchQuery,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      setData(response.data.Data);
    } catch (error) {
      console.error('Error searching data:', error);
    }
  };

  return (
    <div>
      <div className='container' style={{marginLeft:'-25px', marginRight:'-25px'}}>
        <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-lg-3'>
          <input
            type="text"
            name="CW1"
            value={formData.CW1}
            onChange={handleInputChange}
            placeholder="CW1"
            className='form-control'
          />
          </div>
          <div className='col-lg-3'>
          <input
            type="text"
            name="Dominio"
            value={formData.Dominio}
            onChange={handleInputChange}
            placeholder="Dominio"
            className='form-control'
          />
          </div>
          <div className='col-lg-3'>
          <input
            type="text"
            name="CW1Name"
            value={formData.CW1Name}
            onChange={handleInputChange}
            placeholder="CW1Name"
            className='form-control'
          />
          </div>
          <div className='col-lg-3'>
          <button type="submit" className='btn btn-primary'>Add Data</button>
          </div>
          </div>
        </form>
      </div>
      <hr></hr>
       {/* Search Input and Button */}
       <div className="container pl-0 pr-0" style={{marginLeft:'-25px', marginRight:'-25px'}}>
        <form onSubmit={handleSearchSubmit}>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by keyword"
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <table className="table table-striped">
  <thead style={{background:'#240fdf', color:'#fff'}}>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">CW1</th>
      <th scope="col">Dominio</th>
      <th scope="col">NewCW1Name</th>
      <th>Action</th>
      <th>Action</th>
      
    </tr>
  </thead>
  <tbody>
  {currentItems.map((item) => (
    <tr key={item?.ID}>
      <td>{item?.ID}</td>
      <td>
        {item?.ID === updateData?.ID ? (
          <input
            type="text"
            name="NewCW1"
            value={updateData.NewCW1}
            onChange={handleUpdateInputChange}
            className="form-control"
          />
        ) : (
          item?.CW1
        )}
      </td>
      <td>
        {item?.ID === updateData?.ID ? (
          <input
            type="text"
            name="NewDominio"
            value={updateData.NewDominio}
            onChange={handleUpdateInputChange}
            className="form-control"
          />
        ) : (
          item?.Dominio
        )}
      </td>
      <td>
        {item?.ID === updateData?.ID ? (
          <input
            type="text"
            name="NewCW1Name"
            value={updateData.NewCW1Name}
            onChange={handleUpdateInputChange}
            className="form-control"
          />
        ) : (
          item?.Dominio
        )}
      </td>
      <td>
        {item?.ID === updateData?.ID ? (
          <button
            className="btn btn-success"
            onClick={() => handleUpdateSubmit(item?.ID)}
          >
            Save
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={() => handleEditClick(item)}
          >
            Edit
          </button>
        )}
      </td>
      <td>
        {item?.ID !== updateData.ID && (
          <button
            className="btn btn-danger"
            onClick={() => handleDelete(item?.ID)}
          >
            Delete
          </button>
        )}
      </td>
    </tr>
  ))}
</tbody>
      </table>
      <div>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={data.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>

        {/* Update Form */}
     
    </div>
  );
};

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];
  const maxVisiblePages = 5; // Maximum number of page links to show before showing "previous" and "next"
  
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const totalPages = pageNumbers.length;

  return (
    <ul className="pagination1">
      {currentPage > maxVisiblePages && (
        <li>
          <a onClick={() => paginate(1)} href="#!">
            First
          </a>
        </li>
      )}
      {pageNumbers
        .slice(
          Math.max(currentPage - maxVisiblePages, 0),
          currentPage + maxVisiblePages
        )
        .map((number) => (
          <li key={number}>
            <a
              onClick={() => paginate(number)}
              href="#!"
              className={currentPage === number ? "active" : ""}
            >
              {number}
            </a>
          </li>
        ))}
      {currentPage < totalPages - maxVisiblePages && (
        <li>
          <a onClick={() => paginate(totalPages)} href="#!">
            Last
          </a>
        </li>
      )}
    </ul>
  );
};


export default AddBranch;
