import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';

function ConnectionsAdd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const [showPass, setShowPass] = useState(false);
    const [showConPass, setShowConPass] = useState(false);
    const [inputValues, setInputValues] = useState({
        connection_URL: '',
        connection_name: '',
        connection_username: '',
        connection_password: '',
        connection_confPassword: ''
    })

    const addConn = () => {
        if (inputValues.connection_URL && inputValues.connection_name && inputValues.connection_username && inputValues.connection_password && inputValues.connection_confPassword) {
            if (inputValues.connection_password !== inputValues.connection_confPassword) {
                NotificationManager.error('Confirm Password does not match', '', 3000);
            } else {
                // let accessToken = JSON.parse(localStorage.userdata).access_token;
                const body = {
                    "connection_id": 0,
                    "oper": "I",
                    "connection_name": inputValues.connection_name,
                    "connection_url": inputValues.connection_URL,
                    "connection_username": inputValues.connection_username,
                    "connection_password": inputValues.connection_password,
                    "isactive": "Y",
                    "createdby": 1
                };
                http.post(`api/v1/connection/post`,
                    body)
                    .then((res) => {
                        if (res.Success) {
                            NotificationManager.success(res.ResultMessage, '', 3000);
                            setTimeout(() => {
                                history.push(appRoute.Connections)
                            }, 3500);
                        } else {
                            NotificationManager.error(res.ResultMessage, '', 3000);
                        }
                    }).catch((error) => {
                        console.log(error)
                        NotificationManager.error('Something went wrong', '', 3000);
                    })
            }
        } else {
            NotificationManager.error('Please fill all fields', '', 3000);
        }
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Add New Connection</div>
            <div className='sorceDataDiv'>
                <table className='source'>
                    <tbody>
                        <tr>
                            <th>Connection Name</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.connection_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_name: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>Connection URL</th>
                            <th>:</th>
                            <td>
                                <input type='text' value={inputValues.connection_URL} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_URL: e.target.value })} />
                            </td>
                        </tr>
                        <tr>
                            <th>User Name</th>
                            <th>:</th>
                            <td><input type='text' value={inputValues.connection_username} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_username: e.target.value })} /></td>
                        </tr>
                        <tr>
                            <th>Connection Password</th>
                            <th>:</th>
                            <td className='passTd'>
                                <input type={showPass ? 'text' : 'password'} value={inputValues.connection_password} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_password: e.target.value })} />
                                {
                                    showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Confirm Password</th>
                            <th>:</th>
                            <td className='passTd'>
                                <input type={showConPass ? 'text' : 'password'} value={inputValues.connection_confPassword} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_confPassword: e.target.value })} />
                                {
                                    showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Button variant='contained' className='editNsave' onClick={addConn}>Save</Button>
            </div>
        </div>
    )
}

export default ConnectionsAdd
