import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField } from '@material-ui/core';
import { AddOutlined, EditOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getComparator, stableSort } from '../../component/table/function';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import './integration.css';
import EnhancedTableHead from '../../component/table/EnhancedTableHead';

const Integration = () => {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('integration_name');
    const [integrationList, setIntegrationList] = useState([])
    const [apiLoading, setApiLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const getIntegrations = async () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        setApiLoading(true);
        const body = {
            "SearchText": searchTerm.trim(),
            "PageSize": rowsPerPage,
            "CurrentPage": page + 1,
            // "IntegrationId": null,
            // "ActivationStatus": null,
            // "Organization": null,
            "SortOrder": order,
            "SortCol": orderBy
        }

        await http.post(`/api/v1/Integration/IntegrationMaster/Get`, body)
            .then((res) => {
                setTotalRecords(res?.RecordCount ?? 0)
                setIntegrationList(res?.Value ?? []);
                setApiLoading(false);
            }).catch((error) => {
                console.log(error);
                NotificationManager.error('Something went wrong', '', 3000);
                setApiLoading(false);
            })
    }

    useEffect(() => {
        getIntegrations()
    }, [searchTerm, rowsPerPage, page, order, orderBy])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const handleRequestSort = (e, column) => {
        if (orderBy === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
          } else {
            setOrderBy(column);
            setOrder('desc');
        }
    };

    if (apiLoading) {
        return null;
    }

    const headCells = [
        { id: 'integration_name', label: 'Integrations', sortable: true },
        { id: 'integration_type_name', label: 'Type', sortable: true },
       // { id:'integration_org_code', label: 'Organization', sortable: true },
        { id: 'integration_app_id', label: 'App ID', sortable: true },
        { id: 'createdon', label: 'Last Updated', sortable: true },
        { id: 'integration_status', label: 'Status', sortable: true }
    ];

    return (
        <div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}  >
            <div className='mainSectionHeading withAddnew'>
                <span>Integrations</span>
                <span>
                    <Button variant='contained' onClick={() => {
                        history.push(appRoute.AddIntegration);
                    }} endIcon={<AddOutlined />}>Add Integration</Button>
                </span>
            </div>
            <div className='tableParent'>
                {/* <TextField
                    label="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ marginBottom: 16 }}
                    size="small"
                /> */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                label="Search"
                                variant="standard"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ width: '300px', float: 'right'}}
                            />
                        </div>
                    </div>
                </div>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            action={true} />
                        <TableBody>
                            {
                                integrationList.length ?
                                    stableSort(integrationList, getComparator(order, orderBy))
                                        // integrationList
                                        .map((row, index) => {
                                            let count = (index + 1) + (1 * (page * rowsPerPage));
                                            let status = row.integration_status ? "Active" : "Inactive";
                                            let date = moment(row.createdon).format('DD-MM-yyy H:m A');

                                            return (
                                                <TableRow hover key={count} className={!row.status && 'notActive'}>
                                                    <TableCell align='center'>{count}</TableCell>
                                                    <TableCell title={row.integration_name}>{row.integration_name}</TableCell>
                                                    <TableCell title={row.integration_type_name}>{row.integration_type_name}</TableCell>
                                                   
                                                    <TableCell title={row.integration_app_id}>{row.integration_app_id ?? ""}</TableCell>
                                                    <TableCell title={row.createdon}>{date}</TableCell>
                                                    <TableCell title={status} className='status'>
                                                        <span className={row.integration_status ? `integActive` : `integDeactive`} title={status}>{status}</span>
                                                    </TableCell>
                                                    <TableCell title='View Details'>
                                                        <IconButton color="primary" aria-label="Edit Integration" size="small" onClick={() => {
                                                            history.push(`/integrations/edit/${row.integration_id}`);
                                                        }}>
                                                            <EditOutlined />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={5} align={'center'}>No integrations found</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div >
    )
}

export default Integration
