import React, { useContext, useEffect, useState } from 'react';
import { TableContainer, TableSortLabel, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import { StoreContext } from '../../Store/Provider';
import './organizations.css';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import { NotificationManager } from 'react-notifications';
import http from '../../api'
import { useSelector } from 'react-redux';
import { Http } from '@material-ui/icons';

function Organizations() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const context = useContext(StoreContext);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('organization_name');
    const [connectionList, setConnectionList] = useState([])
    const [apiLoading, setApiLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getConnList()
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getConnList = async () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        setApiLoading(true);

        const body = {
            "SearchText": "",
            "PageSize": 20,
            "CurrentPage": 1,
            "ActivationStatus": "",
            "Organization": null,
            "SortOrder": "Desc",
            "SortCol": "Organization_Name"
        };

        http.post(`api/v1/Organization/get`,
            body)
            .then((res) => {
                setConnectionList(res?.Value ?? []);
                setApiLoading(false);
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('Something went wrong', '', 3000);
                setApiLoading(false);
            })
    }

    if (apiLoading) {
        return null;
    }

    return (
        <div className={`mainSection reportsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading withAddnew'>
                <span>Organizations List</span>
                <Button variant='contained' onClick={() => {
                    history.push(appRoute.AddOrg);
                }}>Add Organization</Button>
            </div>
            <div className='tableParent'>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort} />
                        <TableBody>
                            {
                                connectionList.length ?
                                    stableSort(connectionList, getComparator(order, orderBy))
                                        // connectionList
                                        .map((row, index) => {
                                            let count = index + 1;
                                            return (
                                                <TableRow hover key={count}>
                                                    <TableCell align='center'>{index + 1}</TableCell>
                                                    {/* <TableCell align='center' title={row.connection_id}>{row.connection_id}</TableCell> */}
                                                    <TableCell title={row.organization_name}>{row.organization_name}</TableCell>
                                                    <TableCell title={row.organization_code}>{row.organization_code}</TableCell>
                                                    <TableCell title={row.organization_contact}>{row.organization_contact}</TableCell>
                                                    <TableCell title={row.organization_mail}>{row.organization_mail}</TableCell>
                                                    {/* <TableCell title={row.organization_contactperson}>{row.organization_contactperson}</TableCell> */}
                                                    <TableCell title='Edit' align='center'>
                                                        <Button variant='contained' onClick={() => {
                                                            history.push(appRoute.EditOrg, row)
                                                        }}>Edit</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={6} align={'center'}>No organizations found</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div >
    )
}

function descendingComparator(a, b, orderBy) {
    // if (orderBy === 'date') {
    //     return (new Date(b[orderBy].split("-").reverse().join("-")).getTime().valueOf() - new Date(a[orderBy].split("-").reverse().join("-")).getTime().valueOf());
    // }
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    // { id: 'connection_id', label: 'Id', align: 'center' },
    { id: 'organization_name', label: 'Organization Name' },
    { id: 'organization_code', label: 'Organization Code' },
    { id: 'organization_contact', label: 'Organization Contact' },
    { id: 'organization_mail', label: 'Organization Mail' },
    // { id: 'organization_contactperson', label: 'Contact Person' }
];

const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell align='center'>S. No.</TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        align={headCell.align ? headCell.align : 'left'}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell align='center'>Edit</TableCell>
            </TableRow>
        </TableHead>
    );
}

export default Organizations