import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import appRoute from "../../Route/appRoutes";
import http from "../../api";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../component/table/function";

function EndpointAdd() {
  const closeSidebar = useSelector((state) => state.reducer.closeSidebar);
  const history = useHistory();
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [endpointStatus, setEndpointStatus] = useState(true);
  const isEdit = id ? true : false;
  const [endpointTypes, setEndpointTypes] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    endpoint_type_id: "",
    description: "",
    hostname: "",
    port: "",
    username: "",
    password: "",
    url: "",
    absolute_path: "",
    key: "",
    integration_id:"",
    purposecode:"",
    isactive:""
  });

  useEffect(() => {
    http.post("api/v1/Integration/EndpointType/Get").then((res) => {
      setEndpointTypes(res?.Value ?? []);
      if (!isEdit) {
        setIsLoading(false);
      }
    });
    const body = {
      SearchText: "",
      CurrentPage: 1,
      PageSize: 9999,
      SortOrder: "asc",
      SortCol: "integration_name",
    };
    http
      .post(`/api/v1/Integration/IntegrationMaster/Get`, body)
      .then((res) => {
        setIntegrations(res?.Value ?? []);
      })
      .catch((error) => {
        showErrorMessage("Something went wrong", "", 3000);
      });
    if (isEdit) {
      const body = {
        id: id,
      };

      http
        .post(`api/v1/Integration/EndpointMaster/Get`, body)
        .then((res) => {
          if (res.Value) {
            const endpoint = res.Value ? res.Value[0] : [];
            setFormData({
              name: endpoint.endpoint_name,
              endpoint_type: endpoint.endpoint_type_name.trim() ?? "",
              description: endpoint.endpoint_description.trim() ?? "",
              hostname: endpoint.endpoint_hostname ?? "",
              port: endpoint.endpoint_port ?? "",
              username: endpoint.endpoint_username ?? "",
              password: endpoint.endpoint_password ?? "",
              absolute_path: endpoint.endpoint_absolute_path ?? "",
              url: endpoint.endpoint_url ?? "",
              key: endpoint.endpoint_key ?? "",
              purposecode: endpoint.endpoint_purposecode ?? "",
              integration_id: endpoint.endpoint_integration_id ?? "",
              isactive: endpoint.isactive,              
            });
            setEndpointStatus(endpoint.isactive);
            setIsLoading(false);
          }
          
        })
        .catch((error) => {
          console.log(error);
          showErrorMessage("Something went wrong");
        });
    }
  }, [id, isEdit]);

  const handleFormFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name.length || !formData.endpoint_type.length) {
      showErrorMessage("Please fill all fields");
      return;
    }

    const endpointType = formData.endpoint_type.toLowerCase();

    if (
      ["sftp", "ftp", "email"].indexOf(endpointType) !== -1 &&
      (!formData.hostname.length ||
        !formData.username.length ||
        !formData.password.length ||
        !formData.absolute_path.length)
    ) {
      showErrorMessage("Please fill all fields");
      return;
    }

    if (["api"].indexOf(endpointType) !== -1 && !formData.url.length) {
      showErrorMessage("URL is required field.");
      return;
    }

    // if ( ['cw1'].indexOf(endpointType) !== -1  && ( ! formData.username.length || ! formData.password.length ) ) {
    //     showErrorMessage('Please fill all fields');
    // 	return
    // }

    if (
      ["folderlocation"].indexOf(endpointType) !== -1 &&
      !formData.absolute_path.length
    ) {
      showErrorMessage("Please fill all fields");
      return;
    }

    let endpoint = endpointTypes.filter(
      (endpointType) =>
        endpointType?.endpoint_type_desc?.trim() ===
        formData?.endpoint_type?.trim()
    )[0];
    if (!endpoint) {
      showErrorMessage("Invalid endpoint type.");
      return;
    }

    const body = {
      oper: isEdit ? "U" : "I",
      isactive: true,
      createdby: parseInt(JSON.parse(localStorage.userdata).id),
      endpoint_name: formData.name,
      endpoint_type_id: endpoint.endpoint_type_id,
      endpoint_hostname: formData.hostname,
      endpoint_username: formData.username,
      endpoint_password: formData.password,
      endpoint_port: formData.port,
      endpoint_url: formData.url,
      endpoint_key: formData.key,
      endpoint_absolute_path: formData.absolute_path,
      endpoint_integration_id: selectedIntegration,
      endpoint_description: formData.description,
      endpoint_purposecode: formData.purposecode,
      isactive: formData.isactive,
    };

    if (isEdit) {
      body["endpoint_id"] = id;
    }

    http
      .post(`/api/v1/Integration/EndpointMaster/POST`, body)
      .then((res) => {
        if (res.Success) {
          showSuccessMessage(res.ResultMessage);
          setTimeout(() => {
            history.push(appRoute.Endpoints);
          }, 3500);
          e.target.reset();
        } else {
          showErrorMessage(res.ResultMessage);
        }
      })
      .catch((error) => {
        console.log(error);
        showErrorMessage("Something went wrong");
      });
  };

  if (isLoading) {
    return null;
  }

  return (
    <div
      className={`mainSection IntegDetailsComponent ${
        closeSidebar ? "maxMainSection" : "minMainSection"
      }`}
    >
      <div className="mainSectionHeading">
        {isEdit ? "Edit" : "Add New"} Endpoint
      </div>
      <div className="sorceDataDiv mt-3">
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <label htmlFor="name" className="col-4 col-form-label">
              Name:
            </label>
            <div className="col-8">
              <input
                type="text"
                value={formData.name}
                id="name"
                name="name"
                className="form-control"
                onChange={handleFormFieldChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="purposecode" className="col-4 col-form-label">
              Purpose Code:
            </label>
            <div className="col-8">
              <input
                type="text"
                value={formData.purposecode}
                id="purposecode"
                name="purposecode"
                className="form-control"
                onChange={handleFormFieldChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="endpoint_type" className="col-4 col-form-label">
              Endpoint Type:
            </label>
            <div className="col-8">
              <select
                className="form-select"
                name="endpoint_type"
                id="endpoint_type"
                value={formData.endpoint_type}
                onChange={handleFormFieldChange}
              >
                <option value="">-- Select --</option>
                {endpointTypes.map((endpointType, index) => (
                  <option
                    key={index}
                    value={endpointType.endpoint_type_desc.trim()}
                  >
                    {endpointType.endpoint_type_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {formData.endpoint_type &&
          ["sftp", "ftp", "email"].indexOf(
            formData.endpoint_type.toLowerCase()
          ) !== -1 ? (
            <>
              <div className="row mb-3">
                <label htmlFor="hostname" className="col-4 col-form-label">
                  Hostname:
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    value={formData.hostname}
                    id="hostname"
                    name="hostname"
                    className="form-control"
                    onChange={handleFormFieldChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="port" className="col-4 col-form-label">
                  Port:
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    value={formData.port}
                    id="port"
                    name="port"
                    className="form-control"
                    onChange={handleFormFieldChange}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {formData.endpoint_type &&
          (formData.endpoint_type.toLowerCase() === "cw1" ||
            formData.endpoint_type.toLowerCase() === "api") ? (
            <div className="row mb-3">
              <label htmlFor="url" className="col-4 col-form-label">
                URL:
              </label>
              <div className="col-8">
                <input
                  type="text"
                  value={formData.url}
                  id="url"
                  name="url"
                  className="form-control"
                  onChange={handleFormFieldChange}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {formData.endpoint_type &&
          formData.endpoint_type.toLowerCase() === "api" ? (
            <div className="row mb-3">
              <label htmlFor="key" className="col-4 col-form-label">
                Key:
              </label>
              <div className="col-8">
                <input
                  type="text"
                  value={formData.key}
                  id="key"
                  name="key"
                  className="form-control"
                  onChange={handleFormFieldChange}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {formData.endpoint_type &&
          ["sftp", "ftp", "email", "cw1"].indexOf(
            formData.endpoint_type.toLowerCase()
          ) !== -1 ? (
            <>
              <div className="row mb-3">
                <label htmlFor="username" className="col-4 col-form-label">
                  Username:
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    value={formData.username}
                    id="username"
                    name="username"
                    className="form-control"
                    onChange={handleFormFieldChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="password" className="col-4 col-form-label">
                  Password:
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    value={formData.password}
                    id="password"
                    name="password"
                    className="form-control"
                    onChange={handleFormFieldChange}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {formData.endpoint_type &&
          ["sftp", "ftp", "email"].indexOf(
            formData.endpoint_type.toLowerCase()
          ) !== -1 ? (
            <div className="row mb-3">
              <label htmlFor="absolute_path" className="col-4 col-form-label">
                Absolute Path:
              </label>
              <div className="col-8">
                <input
                  type="text"
                  value={formData.absolute_path}
                  id="absolute_path"
                  name="absolute_path"
                  className="form-control"
                  onChange={handleFormFieldChange}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {formData.endpoint_type &&
          ["folderlocation"].indexOf(formData.endpoint_type.toLowerCase()) !==
            -1 ? (
            <>
              <div className="row mb-3">
                <label htmlFor="absolute_path" className="col-4 col-form-label">
                  Absolute Path:
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    value={formData.absolute_path}
                    id="absolute_path"
                    name="absolute_path"
                    className="form-control"
                    onChange={handleFormFieldChange}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {formData.endpoint_type &&
          ["sftp", "ftp", "email"].indexOf(
            formData.endpoint_type.toLowerCase()
          ) !== -1 ? (
            <div className="row mb-3">
              <label htmlFor="password" className="col-4 col-form-label">
                Integration
              </label>
              <div className="col-8">
                <select
                  className="form-select form-select1"
                  onChange={(e) => setSelectedIntegration(e.target.value)}
                  value={formData.integration_id}
                >
                  <option value="">Select Integration</option>
                  {integrations.map((ele, index) => {
                    return (
                      <option key={index + 1} value={ele.integration_id}>
                        {ele.integration_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row mb-3">
                        <label className="form-check-label col-4" htmlFor="endpoint_status">Status:</label>
                        <div className="col-8">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="endpoint_status_yes_no" onChange={(e) => setEndpointStatus(e.target.checked)} checked={formData.isactive} />
                                <label className="form-check-label" htmlFor="endpoint_status_yes_no">{endpointStatus ? 'Active' : 'Inactive'}</label>
                            </div>
                        </div>
                    </div>
          <Button type="submit" variant="contained" className="editNsave">
            Save
          </Button>
        </form>
      </div>
    </div>
  );
}

export default EndpointAdd;
