import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { Button } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import appRoute from '../../../Route/appRoutes';
import http from '../../../api';
import './integrationDetails.css';

function DestinationEnd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    // const [edit, setEdit] = useState(false);
    // const edit = false;
    const location = useLocation();
    const [integrationName, setIntegrationName] = useState('')
    const [integrationId, setIntegrationId] = useState(0);
    // const [inputValues, setInputValues] = useState([]);
    const [destinationList, setdestinationList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        await setIntegrationId(new URLSearchParams(location.search).get("integration_id"));
        await setIntegrationName(new URLSearchParams(location.search).get("integration_name"));
    }, [location]);

    useEffect(async () => {
        setLoading(true);
        if (integrationId) {
            getDetails(integrationId);
        }
    }, [integrationId])

    const handleChange = (ele, index, event) => {
        if (event.target.checked === false) {
            destinationList[index].destination_integration = 0;
            setdestinationList([...destinationList])
        } else {
            destinationList[index].destination_integration = ele.destination_endpoint_id;
            setdestinationList([...destinationList])
        }
    }

    const getDetails = (integrationId) => {
        const params =
        {
            "SearchText": "",
            "PageSize": 20,
            "CurrentPage": 1,
            "Id": integrationId,
            "ActivationStatus": null,
            "Organization": null,
            "SortOrder": "Desc",
            "SortCol": "Integration_Name"
        }
        http.post(`api/v1/integration/get`,
            params)
            .then((res) => {
                setdestinationList(res?.Value[0]?.integration_destination ?? [])
                setLoading(false);
                // setInputValues(res?.Value[0] ?? []);
            }).catch((error) => {
                console.log(error)
                setLoading(true);
                NotificationManager.error('Destination end point API fails! Something went wrong', '', 3000);
            })
    }

    const editDest = () => {
        let destid = "";
        destinationList.forEach((ele, index) => {
            if (ele.destination_integration) destid += `${ele.destination_endpoint_id},`
        })
        console.log(destid)
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        const body = {
            "integration_id": parseInt(integrationId),
            "integration_destinations": destid
        }
        console.log(body);
        http.post(`api/v1/Integration/mapping/destination`,
            body)
            .then(res => {
                if (res.Success) {
                    NotificationManager.success(res.ResultMessage, '', 3000);
                } else {
                    NotificationManager.error(res.ResultMessage, '', 3000);
                }
            }).catch((error) => {
                console.log(error)
                NotificationManager.error('Something went wrong', '', 3000);
            })
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Destination End Point</div>
            <div className='projectRoutes px-3'> <Link to={appRoute.INTEGRATION}>INTEGRATIONS</Link> / {integrationName.toUpperCase()} </div>
            {/* <div className='mainSectionHeading'>Destination End Point</div> */}
            {
                destinationList.length ?
                    <div className='sorceDataDiv px-4'>
                        {
                            destinationList.map((ele, index) => {
                                let checked = ele.destination_integration ? true : false;
                                return (
                                    <div className='col-md-6 col-12' key={index}>
                                        <FormGroup onChange={(event) => handleChange(ele, index, event)}>
                                            <FormControlLabel className='forcontrolSelectLabel'
                                                control={
                                                    <Checkbox name={ele.destination_endpoint_name} color="primary" checked={checked}
                                                    />
                                                }
                                                label={<span className='forcontrolSelectLabelSpan' title={ele.destination_endpoint_name}>{ele.destination_endpoint_name}</span>}
                                            />
                                        </FormGroup>
                                    </div>
                                )
                            })
                        }
                        <Button variant='contained' className='editNsave' onClick={editDest}>Save</Button>
                    </div>
                    :
                    <div className='sorceDataDiv px-3'>
                        <div className='mainSectionHeading'>No destination end point associated</div>
                    </div>
            }
            {/* {
                    inputValues.length !== 0 &&
                    <table className='source'>
                        <tbody>
                            <tr>
                                <th>Destination Type</th>
                                <th>:</th>
                                <td>
                                    <select className={!edit ? 'form-control showInputText' : 'form-control'} disabled={edit ? false : true} value={inputValues.destination_endpoint_type_name?.trim()} onChange={(e) => setInputValues({ ...inputValues, destination_endpoint_type_name: e.target.value })} >
                                        <option value='FTP'>FTP</option>
                                        <option value='SFTP'>SFTP</option>
                                        <option value='SQL'>SQL</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Connection Name</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.connection_name} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_name: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Connection Url</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.connection_url} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_url: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Destination Endpoint Name</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.destination_endpoint_name} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, destination_endpoint_name: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Destination Endpoint Absolute Path</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.destination_endpoint_absolute_path} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, destination_endpoint_absolute_path: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>User ID</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.connection_username} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_username: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Password</th>
                                <th>:</th>
                                <td><input type={edit ? 'text' : 'password'} disabled={edit ? false : true} value={inputValues.connection_password} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_password: e.target.value })} /></td>
                            </tr>
                        </tbody>
                    </table>
                } */}
            {/* {
                    edit ?
                        <Button variant='contained' className='editNsave' onClick={() => { setEdit(edit => !edit); console.log(inputValues) }}>Save</Button>
                        :
                        <Button variant='contained' className='editNsave' onClick={() => setEdit(edit => !edit)}>Edit</Button>
                } */}
        </div>
    )
}

export default DestinationEnd
