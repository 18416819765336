import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Snackbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/core/Alert";
import { controlledRoute, universalRoute } from "./routeConfig";
import ProtectedRoute from "./ProtectedRoute";
import { StoreContext } from "../Store/Provider";
import http from "../api";
import { ProjectName } from "../config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Navigation() {
  const [online, setOnline] = useState(window.navigator.onLine);
  const [openalert, setOpenalert] = useState(false);
  const context = useContext(StoreContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenalert(false);
  };

  useEffect(() => {
    const getSidebar = () => {
      // let accessToken = JSON.parse(localStorage.userdata).access_token;
      let userId = JSON.parse(localStorage.userdata).id;
      http
        .get(`api/v1/User/Menu/${userId}`)
        .then((res) => {
          // localStorage.setItem("sidebarData", JSON.stringify(res.data.Value));
          context.setmenuList(res?.Value ?? []);
        })
        .catch((err) => console.log(err));
    };
    if (localStorage.getItem("userdata")) {
      getSidebar();
    }
    window.addEventListener("offline", () => setOnline(false));
    window.addEventListener("online", () => setOnline(true));
  }, []);

  useEffect(() => {
    !online && setOpenalert(true);
  }, [online]);

  return (
    <Router basename={`${ProjectName}`}>
      <div className="theme">
        {online ? (
          <Switch>
            {universalRoute.map((route, index) => {
              if (route.isPrivate) {
                return (
                  <ProtectedRoute
                    key={index}
                    path={route.path}
                    component={route.component}
                    exact={route.isExact}
                  />
                );
              }
              return (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.isExact}
                />
              );
            })}
            {controlledRoute.map((route, index) => {
              if (route.isPrivate) {
                return (
                  <ProtectedRoute
                    key={index}
                    path={route.path}
                    component={route.component}
                    exact={route.isExact}
                    userAccess={route.userAccess}
                  />
                );
              }
              return (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.isExact}
                />
              );
            })}
          </Switch>
        ) : (
          <div className="noInternet">
            <img src={`/${ProjectName}/Assets/Logo.svg`} alt="Logo" />
            <p>Connect to the internet</p>
            <p>You're offline. Check your connection.</p>
            <Button onClick={() => window.location.reload()}>RETRY</Button>
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={openalert}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity={"error"} onClose={handleClose}>
            Internet connection is interrupted!
          </Alert>
        </Snackbar>
      </div>
    </Router>
  );
}

export default Navigation;
