import React from 'react';
import './App.css';
import Navigation from './Route/Navigation';
import { Provider } from './Store/Provider';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


function App() {
    return (
        <Provider>
            <NotificationContainer />
            <Navigation />
        </Provider>
    )
}

export default App
