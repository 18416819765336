import React, { useEffect } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function StackBarChartMonthly({ chartData }) {
    useEffect(() => {
        const initializeChart = () => {
            am4core.ready(function () {
                am4core.useTheme(am4themes_myTheme);
                am4core.addLicense('ch-custom-attribution');
                var chart = am4core.create("inboundchart2", am4charts.XYChart);
                chart.data = chartData
    
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "imonth";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 10
                categoryAxis.renderer.cellStartLocation = 0.1;
                categoryAxis.renderer.cellEndLocation = 0.9;
                let label = categoryAxis.renderer.labels.template;
                label.truncate = true;
                // label.wrap = true;
                // label.rotation = -45;
                // label.horizontalCenter = 'right';
                // label.verticalCenter = 'middle';
                label.maxWidth = 80;
    
                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "Processing Status"; // Add y-axis label
                valueAxis.min = 0;
    
                function createSeries(field, stacked) {
                    var series = chart.series.push(new am4charts.ColumnSeries());
                    series.columns.template.maxWidth = 30
                    series.dataFields.valueY = field;
                    series.dataFields.categoryX = "imonth";
                    series.name = field == 'failure' ? 'Failure' : 'Success';                   
                     series.columns.template.tooltipText = "{name} : [bold]{valueY}[/]";
                    series.stacked = stacked;
                    // series.columns.template.width = am4core.percent();
                }
                createSeries("success", false);
                createSeries("failure", true);
            });
        }

        initializeChart()
    }, [chartData])

    function am4themes_myTheme(target) {
        if (target instanceof am4core.ColorSet) {
            target.list = [
                am4core.color("rgb(69, 54, 199)"),
                am4core.color("rgb(199, 54, 90)"),
            ];
        }
    }

    const width = chartData.length * 80 + 100;

    return (
        <div id="inboundchart2" className="chartInnerContainer inboundChartContainer" style={{ minWidth: width, }}></div>
    )
}

// const data = [
//     {
//         "year": "Integration1",
//         "success": 45,
//         "failed": 24,
//     },
//     {
//         "year": "Integration2",
//         "success": 41,
//         "failed": 17,
//     },
//     {
//         "year": "Integration3",
//         "success": 35,
//         "failed": 40,
//     },
//     {
//         "year": "Integration4",
//         "success": 86,
//         "failed": 17,
//     },
//     {
//         "year": "Integration5",
//         "success": 18,
//         "failed": 23,
//     },
//     {
//         "year": "Integration6",
//         "success": 108,
//         "failed": 40,
//     },
//     {
//         "year": "Integration7",
//         "success": 15,
//         "failed": 10,
//     },
//     {
//         "year": "Integration8",
//         "success": 37,
//         "failed": 12,
//     },
//     {
//         "year": "Integration9",
//         "success": 38,
//         "failed": 40,
//     },
//     {
//         "year": "Integration10",
//         "success": 34,
//         "failed": 11,
//     },
//     {
//         "year": "Integration11",
//         "success": 40,
//         "failed": 21,
//     },
//     {
//         "year": "Integration12",
//         "success": 46,
//         "failed": 47,
//     },
//     {
//         "year": "Integration13",
//         "success": 56,
//         "failed": 28,
//     },
//     {
//         "year": "Integration14",
//         "success": 48,
//         "failed": 2,
//     },
//     {
//         "year": "Integration15",
//         "success": 24,
//         "failed": 10,
//     }
// ];
export default StackBarChartMonthly
