import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import appRoute from '../../../Route/appRoutes';
import './integrationDetails.css';

function Notification() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    // const [edit, setEdit] = useState(false);
    const edit = false;
    const [inputValues, setInputValues] = useState('admin@admin.com');
    const location = useLocation();
    const [integrationName, setIntegrationName] = useState('')
    const [integrationId, setIntegrationId] = useState(0);

    useEffect(() => {
        setIntegrationId(new URLSearchParams(location.search).get("integration_id"));
        setIntegrationName(new URLSearchParams(location.search).get("integration_name"));
    }, [location]);

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}  >
            <div className='mainSectionHeading'>Notifications</div>
            <div className='projectRoutes'> <Link to={appRoute.INTEGRATION}>INTEGRATIONS</Link> / {integrationName.toUpperCase()} </div>
            {/* <div className='mainSectionHeading'>Notifications</div> */}
            <div className='sorceDataDiv'>
                <table className='source'>
                    <tbody>
                        <tr>
                            <th>Notifications send to</th>
                            <th>:</th>
                            <td>
                                <input type="email" disabled={edit ? false : true} className={!edit ? 'form-control showInputText' : 'form-control'} multiple value={inputValues} onChange={(e) => setInputValues(e.target.value)} />
                                {
                                    edit && <p className='mailInfoUser'>Use ,(comma) for multiple Ids.</p>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* {
                    edit ?
                        <Button variant='contained' className='editNsave' type='submit' onClick={() => { setEdit(edit => !edit); console.log(inputValues) }}>Save</Button>
                        :
                        <Button variant='contained' className='editNsave' type='submit' onClick={() => setEdit(edit => !edit)}>Edit</Button>
                } */}
            </div>
        </div>
    )
}

export default Notification
