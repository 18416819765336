import { createSlice } from "@reduxjs/toolkit";

export const ReduxSlice = createSlice({
    name: 'Store',
    initialState: {
        closeSidebar: false,
    },
    reducers: {
        closeSidebarAction: (state, action) => {
            state.closeSidebar = action.payload
        },
    },
})

export const { closeSidebarAction } = ReduxSlice.actions

export default ReduxSlice.reducer