import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const ContabilAccountingReport = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items to display per page
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const fetchData = async () => {
      setIsLoading(true);
      const apiUrl = 'https://asprod.sflhub.com/MiddlewareAppsAPI/ContabilAccountingReport'; // Updated API URL
    
      const requestData = {
        From: startDate, // Changed "DateFrom" to "From"
        To: endDate, // Changed "DateTo" to "To"
        PageNumber: 1,
        PageSize: 5000000,
      };
    
      try {
        const response = await axios.post(apiUrl, requestData, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
    
        const jsonData = response.data.Data;
        setCsvData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    const downloadExcel = () => {
      const headers = Object.keys(csvData[0]);
      const worksheetData = [headers, ...csvData.map(row => headers.map(header => row[header]))];
  
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');
  
      XLSX.writeFile(newWorkbook, 'data.csv');
    };
    const totalPages = Math.ceil(csvData.length / itemsPerPage);
  
    // Calculate the range of pages to display in pagination
    const pageRange = 10; // Number of page numbers to show
    let startPage = Math.max(currentPage - Math.floor(pageRange / 2), 1);
    let endPage = Math.min(startPage + pageRange - 1, totalPages);
  
    if (totalPages - endPage < Math.floor(pageRange / 2)) {
      startPage = Math.max(endPage - pageRange + 1, 1);
    }

    const handleDownloadClick = () => {
      setLoading(true); // Show loader
      // Prepare the data object
      const data = {
        From: startDate, // Changed "DateFrom" to "From"
        To: endDate,
      };
  
      // Send a POST request to the API
      fetch('https://asprod.sflhub.com/MiddlewareAppsAPI/ContabilAccountingReportDownload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/csv',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the CSV file
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ContabilAccountingReport.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error:', error);
        });
    };

  return (
    <div className='w-100'>
      <div className='container mt-5 mb-3'>
      

          <div className='row'>
            <div className='col-lg-4'>
              <label>Start Date:</label>
              <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className='form-control'
        />
            </div>
            <div className='col-lg-4'>
            <label>End Date:</label>
            <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className='form-control'
        />
            </div>
            <div className='col-lg-2 mt-3'> <button onClick={fetchData} disabled={isLoading} className='btn btn-primary w-100'> {isLoading ? 'Fetching...' : 'Fetch Data'}</button></div>
            <div className='col-lg-2 mt-3'>
            <button onClick={downloadExcel} disabled={!csvData.length} className='btn btn-success w-100 d-none'>
        Download Excel
      </button>
       {/* <button onClick={handleDownload} className='btn btn-success w-100'>Download CSV</button>*/}
       <button onClick={handleDownloadClick} disabled={loading} className='btn btn-success w-100'>
        {loading ? <div className="loader123"></div> : 'Download CSV'}
      </button>
            </div>
          </div>
   
     <hr className='ms-0 me-0'></hr>
      <div className='row ms-0 me-0'>
      <table className="table table-striped w-100">
        <thead style={{background:'#0d6efd', color:'#fff'}}>
          {/* ... (Table headers) */}
          <tr>
            {/* Render table headers */}
            <th>Ledger</th>
            <th>Invoice Type</th>
            <th>Invoice Date</th>
            <th>Credito</th>
            <th>Debito</th>
            <th>Valor</th>
            <th>Pedido</th>
            <th>Producto</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="9" style={{ textAlign: 'center' }}>
                Loading...
              </td>
            </tr>
          ) : csvData.length === 0 ? (
            <tr>
              <td colSpan="9" style={{ textAlign: 'center' }}>
                No data available.
              </td>
            </tr>
          ) : (
            csvData
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((row, index) => (
                <tr key={index}>
                  {/* ... (Render table cells) */}
             
                {/* Render table cells */}
                <td>{row.Ledger}</td>
                <td>{row.InoviceType}</td>
                <td>{row.InvoiceDate}</td>
                <td>{row.Credito}</td>
                <td>{row.Debito}</td>
                <td>{row.Valor}</td>
                <td>{row.Pedido}</td>
                <td>{row.Producto}</td>
                
              </tr>
              
              ))
          )}
        </tbody>
      </table>
      </div>

    
      {/* Pagination */}
       {/* Pagination */}
       <div className="pagination text-center">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(pageNumber => (
          <button
            key={pageNumber}
            onClick={() => setCurrentPage(pageNumber)}
            className={pageNumber === currentPage ? "active" : ""}
          >
            {pageNumber}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= totalPages}
        >
          Next
        </button>
      </div>
    </div>
    </div>      
  );
};

export default ContabilAccountingReport;
