import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField } from '@material-ui/core';
import { FilterListOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DateRangePicker from 'rsuite/DateRangePicker';
import Stack from 'rsuite/Stack';
import 'rsuite/dist/rsuite.min.css';
import { StoreContext } from '../../Store/Provider';
import http from '../../api';
import EnhancedTableHead from '../../component/table/EnhancedTableHead';
import { getComparator, getPredefinedDateRanges, stableSort } from '../../component/table/function';
import './reports.css';

const Reports = () => {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const context = useContext(StoreContext);
    const history = useHistory();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('message_filename');
    const [integrations, setIntegrations] = useState([])
    const [selectedIntegration, setSelectedIntegration] = useState('')
    const [reportsList, setReportsList] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [apiLoading, setApiLoading] = useState(true);
    const [filterVisible, setFilterVisible] = useState(false); // Track filter visibility
    const [selectedDateRange, setSelectedDateRange] = useState([]); // Track the selected date range
    const [selectedReportStatus, setSelectedReportStatus] = useState('');

    const predefinedRanges = getPredefinedDateRanges()

    useEffect(() => {
        getReportsList()
    }, [searchTerm, selectedIntegration, selectedDateRange, selectedReportStatus, rowsPerPage, page, order, orderBy])

    const getReportsList = () => {
        // let accessToken = JSON.parse(localStorage.userdata).access_token;
        let formattedFromDate = '';
        let formattedToDate = '';
        if ( selectedDateRange ) {
            const [fromDate, toDate] = selectedDateRange;
            if ( fromDate && toDate ) {
                formattedFromDate = format(fromDate, 'yyyy-MM-dd');
                formattedToDate = format(toDate, 'yyyy-MM-dd');
            }
        }

        const params = {
            "SearchText": searchTerm.trim(),
            "PageSize": rowsPerPage,
            "CurrentPage": page + 1,
            "MessageStatus": selectedReportStatus,
            // "Organization": null,
            "integration": selectedIntegration,
            "FromDate": formattedFromDate,
            "ToDate": formattedToDate,
            "SortOrder": order,
            "SortCol": orderBy
        }

        http.post(`api/v1/integration/getreport`, params)
            .then((res) => {
                setTotalRecords(res?.RecordCount ?? 0)
                setReportsList(res?.Value ?? [])
                setApiLoading(false);
            }).catch((error) => {
                console.log(error);
                NotificationManager.error('Something went wrong', '', 3000);
                setApiLoading(false);
            })
    }

    useEffect(async () => {
        const body = {
            "SearchText": "",
            "SortOrder": 'asc',
            "SortCol": 'integration_name'
        }

        await http.post(`/api/v1/Integration/IntegrationMaster/Get`, body)
            .then((res) => {
                console.log(res.Value)
                setIntegrations(res?.Value ?? []);
            }).catch((error) => {
                console.log(error);
                NotificationManager.error('Something went wrong', '', 3000);
            })
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const handleIntegrationChange = (event) => {
        setSelectedIntegration(event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleToggleFilter = () => {
        setFilterVisible(!filterVisible);
    };

    const handleDateRangeChange = (value) => {
        setSelectedDateRange(value);
    };

    const handleReportStatusChange = (e) => {
        setSelectedReportStatus(e.target.value);
    }

    if (apiLoading) {
        return null;
    }

    const headCells = [
        { id: 'message_filename', label: 'File Name', sortable: true },
        { id: 'message_status', label: 'Status', sortable: true },
        { id: 'message_integration', label: 'Integration', sortable: true },
        { id: 'message_processdate', label: 'Date', sortable: true }
    ];

    return (
        <div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading withAddnew'>
                <span>Reports</span>
                {/* <Button variant='contained' onClick={() => {
                    // history.push(appRoute.AddEndpoint);
                }}>Add Report</Button> */}
                <div className="filters-wrapper">
                    <IconButton
                        color="default"
                        aria-label="Toggle Filter"
                        onClick={handleToggleFilter}
                        >
                        <FilterListOutlined />
                    </IconButton>
                </div>
            </div>
            <div className="container">
                <Collapse in={filterVisible} className='filters-collapse'>
                    <div className={`row ${filterVisible ? 'expanded' : ''}`}>
                        <div className="col-4">
                            <label htmlFor="fromtoPicker">Date Range</label>
                            <Stack direction="column" spacing={8} alignItems="flex-start" className="daterange-filter">
                                <DateRangePicker
                                    ranges={predefinedRanges}
                                    value={selectedDateRange}
                                    onChange={handleDateRangeChange}
                                    placeholder="Select Date Range"
                                    style={{ width: '100%' }}
                                />
                            </Stack>
                        </div>
                        <div className="col-4">
                            <label htmlFor="integration">Integration</label>
                            <select name="integration" id="integration" className="form-select" onChange={handleIntegrationChange} value={selectedIntegration}>
                                <option value="">All</option>
                                {integrations.map((integration, i) =>  (
                                    <option value={integration.integration_id} key={i}>{integration.integration_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <TextField
                                label="Search"
                                variant="standard"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ width: '100%', float: 'right'}}
                            />
                        </div>
                        <div className="col-4 mt-3">
                            <label htmlFor="report_status">Status</label>
                            <select name="report_status" id="report_status" className="form-select" value={selectedReportStatus} onChange={handleReportStatusChange}>
                                <option value="">All</option>
                                <option>Success</option>
                                <option>Processing</option>
                                <option>Errored</option>
                            </select>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className='tableParent'>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            action={true} />
                        <TableBody>
                            {
                                reportsList.length ?
                                    stableSort(reportsList, getComparator(order, orderBy))
                                        // reportsList
                                        .map((row, index) => {
                                            let count = (index + 1) + (1 * (page * rowsPerPage));
                                            let date = moment(row.message_processdate).format('DD-MM-yyy H:m a');
                                            return (
                                                <TableRow hover key={count}>
                                                    <TableCell align='center'>{count}</TableCell>
                                                    <TableCell title={row.message_filename}>
                                                        <a href={row.message_fileurl} target="_blank">{row.message_filename}</a>
                                                    </TableCell>
                                                    <TableCell title={row.message_status} style={{ color: row.message_status == 'Errored' ? 'red' : '' }}>{row.message_status}</TableCell>
                                                    <TableCell title={row.message_integration}>{row.message_integration}</TableCell>
                                                    <TableCell title={date}>{date}</TableCell>
                                                    <TableCell title='View Details'>
                                                        <IconButton color="primary" aria-label="Edit Integration" size="small" onClick={() => {
                                                            history.push(`/reports/log/${row.message_id}`);
                                                        }}>
                                                            <RemoveRedEyeOutlined />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={6} align={'center'}>No reports found</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div >
    )
}

export default Reports
