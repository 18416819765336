import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { Link, useLocation } from 'react-router-dom';
import appRoute from '../../../Route/appRoutes';
import './integrationDetails.css';
// import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../config';
// import http from '../../../st'
import http from '../../../api';

function SourceEnd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const location = useLocation();
    // const [edit, setEdit] = useState(false);
    const edit = false;
    const [inputValues, setInputValues] = useState([])
    const [integrationName, setIntegrationName] = useState('')
    const [integrationId, setIntegrationId] = useState(0);
    useEffect(() => {
        setIntegrationId(new URLSearchParams(location.search).get("integration_id"));
        setIntegrationName(new URLSearchParams(location.search).get("integration_name"));
    }, [location]);

    useEffect(() => {
        if (integrationId) {
            getDetails(integrationId);
        }
    }, [integrationId])

    const getDetails = (integrationId) => {
        const params = {
            "SearchText": "",
            "PageSize": 20,
            "CurrentPage": 1,
            "Integration": integrationId,
            "ActivationStatus": null,
            "Organization": null,
            "SortOrder": "Desc",
            "SortCol": "Source_Endpoint_Id"
        }
        http.post(BASE_URL + "api/v1/Endpoint/get/Source",
            params)
            .then((res) => {
                res.Value.length !== 0 ? setInputValues(res.Value[0]) :
                    NotificationManager.error('No details found', '', 3000);

            }).catch((error) => {
                console.log(error)
            })
    }

    if (inputValues.length) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}  >
            <div className='mainSectionHeading'>Source End Point</div>
            <div className='projectRoutes'> <Link to={appRoute.INTEGRATION}>INTEGRATIONS</Link> / {integrationName.toUpperCase()} </div>
            {/* <div className='mainSectionHeading'>Source End Point</div> */}
            <div className='sorceDataDiv'>
                {
                    inputValues.length !== 0 &&
                    <table className='source'>
                        <tbody>
                            <tr>
                                <th>Source Type</th>
                                <th>:</th>
                                <td>
                                    <select className={!edit ? 'form-control showInputText' : 'form-control'} disabled={edit ? false : true} value={inputValues.source_endpoint_type_name} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_type_name: e.target.value })} >
                                        <option value='FTP'>FTP</option>
                                        <option value='SFTP'>SFTP</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Connection Name</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.connection_name} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_name: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Connection Url</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.connection_url} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_url: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Source Endpoint Name</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.source_endpoint_name} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_name: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Source Endpoint Working Path</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.source_endpoint_working_path} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_working_path: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Source Endpoint Archive Path</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.source_endpoint_archive_path} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_archive_path: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Source Endpoint Absolute Path</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.source_endpoint_absolute_path} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, source_endpoint_absolute_path: e.target.value })} /></td>
                            </tr>
                            {/* <tr>
                                <th>Port</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.port} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, port: e.target.value })} /></td>
                            </tr> */}
                            <tr>
                                <th>User ID</th>
                                <th>:</th>
                                <td><input type='text' disabled={edit ? false : true} value={inputValues.connection_username} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_username: e.target.value })} /></td>
                            </tr>
                            {/* <tr>
                                <th>Password</th>
                                <th>:</th>
                                <td><input type={edit ? 'text' : 'password'} disabled={edit ? false : true} value={inputValues.connection_password} className={!edit ? 'form-control showInputText' : 'form-control'} onChange={(e) => setInputValues({ ...inputValues, connection_password: e.target.value })} /></td>
                            </tr> */}
                        </tbody>

                    </table>

                }
                {/* {
                    edit ?
                        <Button variant='contained' className='editNsave' onClick={() => { setEdit(edit => !edit); console.log(inputValues) }}>Save</Button>
                        :
                        <Button variant='contained' className='editNsave' onClick={() => setEdit(edit => !edit)}>Edit</Button>
                } */}
            </div>
        </div>
    )
}

export default SourceEnd
