import React from 'react';
import { useSelector } from 'react-redux';
import './sidebar.css';

function Sidebar({ sidebarItemList }) {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    return (
        <div className={closeSidebar ? 'sidebarcomp closeSidebar' : 'sidebarcomp openSidebar'}>
            {
                sidebarItemList
            }
        </div>
    )
}

export default Sidebar
