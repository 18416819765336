import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { showErrorMessage, showSuccessMessage } from '../../component/table/function';
import './integration.css';
//import { Info } from '@material-ui/icons';

function IntegrationAdd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const { id } = useParams();
    const isEdit = id ? true : false;
    const [loading, setLoading] = useState(true);
    const [integrationStatus, setIntegrationStatus] = useState(true);
   // const [endpoints, setEndpoints] = useState([]);
   // const [endpointInfo, setEndpointInfo] = useState({});
   // const [organizations, setOrganizations] = useState([]);
    const [integrationTypes, setIntegrationTypes] = useState([]);
    const [formData, setFormData] = useState({
        integration_name: '',
        integration_type: '',
        integration_app_id: '',
        integration_description: '',
        // integration_org_id: '',
        // connection_url: '',
        // source_endpoint: '',
        // filename_pattern: '',
        // content_pattern_1: '',
        // content_pattern_2: '',
        // content_pattern_3: '',
        // runat_every: '',
        // runat_time: '',
        // notification_success_emails: '',
        // notification_failure_emails: '',
    })
    // const runatSlots = Array.apply(null, {length: 60}).map(Number.call, Number);

    const getIntegration = () => {
        const body = {
            "id": id,
        }

        http.post(`/api/v1/Integration/IntegrationMaster/Get`, body)
            .then((res) => {
                const integration = res.Value.length ? res.Value[0] : []
                if ( integration ) {
                    setFormData({
                        integration_name: integration.integration_name,
                        integration_type: integration.integration_type_id,
                        integration_app_id: integration.integration_app_id,
                        integration_description: integration.integration_description,
                    })
                    setIntegrationStatus(integration.integration_status)
                }
            }).catch((error) => {
                console.log(error);
                showErrorMessage('Something went wrong');
            })
    }

    useEffect(() => {
        const getIntegrationTypes = () => {
            http.post(`/api/v1/Integration/IntegrationType/Get`)
                .then(res => {
                    setIntegrationTypes(res?.Value ?? [])
                })
                .catch(err => {
                    console.log(err);
                    showErrorMessage('Failed to load Data');
                })
        }

      /*  const getEndpoints = () => {
            const body = {
                "SortOrder": "desc",
                "SortCol": "endpoint_name"
            }

            http.post(`api/v1/Endpoint/EndpointMaster/Get`, body)
                .then(res => {
                    setEndpoints(res?.Value ?? [])
                })
                .catch(err => {
                    console.log(err);
                    showErrorMessage('Failed to load Data');
                })
        } */

        getIntegrationTypes();
        //getEndpoints();
        setLoading(false);
    }, [])

    /* useEffect(() => {
        const getOrganizations = () => {
            // let accessToken = JSON.parse(localStorage.userdata).access_token;
            const body = {}
            http.post(`api/v1/Integration/Organization/Get`,
                body)
                .then((res) => {
                    setOrganizations(res?.Value ?? [])
                    setLoading(false)
                }).catch((error) => {
                    console.log(error)
                    showErrorMessage('Can not get Organizations! Something went wrong');
                })
        }
        getOrganizations();
    }, [])*/

    useEffect(() => {
        if ( isEdit ) {
            getIntegration()
        }
    }, []) ;


    const handleFormFieldsChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

       /* if ( e.target.name === 'source_endpoint' && e.target.value ) {
            setEndpointInfo(endpoints.filter(endpoint => endpoint.endpoint_id === parseInt(e.target.value))[0])
        }*/
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.integration_name.length || !formData.integration_type ) {
            showErrorMessage('Please fill all the fields.')
            return false;
        }

        // check integration type id
        const body = {
            "oper": isEdit ? "U" : "I",
            "isactive": true,
            "createdby": parseInt(JSON.parse(localStorage.userdata).id),
            "conversion_url": '',
            "integration_name": formData.integration_name,
            "integration_type_id": formData.integration_type,
            "integration_app_id": formData.integration_app_id,
            //"integration_org_id": formData.integration_org_id,
            "integration_description": formData.integration_description,
            //"source_endpoint_id": '',
            "use_pattern_matching": '',
            "filename_pattern": '',
            "content_pattern_01": '',
            "content_pattern_02": '',
            "content_pattern_03": '',
            "destinations": '',
            "notify_onsuccess": '',
            "notify_onfailure": '',
            "notify_forfaliure_at": '',
            "notify_forsuccess_at": '',
            // "runafter": formData.runat_time,
            "runafter": 'm',
            // "runafterevery": formData.runat_every,
            "runafterevery": 1,
            "timer_status": 1,
            "integration_status": integrationStatus
        }

        if ( isEdit ) {
            body.integration_id = parseInt(id);
        }

        http.post(`/api/v1/Integration/IntegrationMaster/POST`, body)
            .then((res) => {
                if (res.Success) {
                    showSuccessMessage(res.ResultMessage);
                    setTimeout(() => {
                        history.push(appRoute.INTEGRATION)
                    }, 3500);
                    e.target.reset()
                } else {
                    showErrorMessage(res.ResultMessage);
                }
            }).catch((error) => {
                console.log(error)
                showErrorMessage('Something went wrong');
            })
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>{isEdit ? 'Edit' : 'Add New'} Integration</div>
            <div className='sorceDataDiv'>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <label htmlFor="integration_name" className="col-3 col-form-label">Integration Name:</label>
                        <div className="col-9">
                            <input type='text' value={formData.integration_name} id="integration_name" name="integration_name" className='form-control' onChange={handleFormFieldsChange} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="integration_type" className="col-3 col-form-label">Integration Type:</label>
                        <div className="col-9">
                            <select name="integration_type" id="integration_type" className="form-select" onChange={handleFormFieldsChange} value={formData.integration_type}>
                                <option>-- Select --</option>
                                {integrationTypes.map((integrationType, index) => (
                                    <option value={integrationType.integration_type_id} key={index}>{integrationType.integration_type_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
               
                    <div className="row mb-3">
                        <label htmlFor="integration_app_id" className="col-3 col-form-label">App ID:</label>
                        <div className="col-9">
                            <input type='number' value={formData.integration_app_id} id="integration_app_id" name="integration_app_id" className='form-control' onChange={handleFormFieldsChange} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="integration_description" className="col-3 col-form-label">Integration Description:</label>
                        <div className="col-9">
                            <textarea name="integration_description" id="integration_description" cols="30" rows="5" className="form-control" onChange={handleFormFieldsChange}>{formData.integration_description}</textarea>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="form-check-label col-3" htmlFor="integration_status">Status:</label>
                        <div className="col-9">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="integration_status_yes_no" onChange={(e) => setIntegrationStatus(e.target.checked)} checked={integrationStatus} />
                                <label className="form-check-label" htmlFor="integration_status_yes_no">{integrationStatus ? 'Active' : 'Inactive'}</label>
                            </div>
                        </div>
                    </div>
                    <Button type="submit" variant='contained' className='editNsave'>Save</Button>
                </form>
            </div>
        </div >
    )
}

export default IntegrationAdd
