const isLogin = () => {
    const userdata = localStorage.getItem("userdata")
    if (userdata) {
        // if (userdata.user_roles === "ADM") {
        //     userRole = true
        // }
        return true
    }
    return false
}

export const removeSpaceReturnValue = (value, type) => {
    let eleminatedSpaceValue;
    if (type === 'tel') {
        eleminatedSpaceValue = value.trim() ? value?.split(',').map(ele => ele.trim()) : []
    } else {
        eleminatedSpaceValue = value.trim() ? value?.replaceAll(" ", "")?.split(',') : [];
    }
    return eleminatedSpaceValue;
}


export default isLogin