import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { showErrorMessage, showSuccessMessage } from '../../component/table/function';
import { ProjectName, URL_UI } from '../../config';
import './../LoginComponent/login.css';

function ResetPassword() {
    const [confpassword, setConfPassword] = useState("")
    const [password, setPassword] = useState("")
    const [showPass, setShowPass] = useState(false);
    const [showConPass, setShowConPass] = useState(false);
    const [passStrength, setPassStrength] = useState('Too Short');
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState({});
    const location = useLocation();
    const history = useHistory()

    useEffect(() => {
        const getUserData = () => {
            setOpen(true);
            const body = {
                "user_resetlink": `${URL_UI}${ProjectName}${location.pathname}`,
                // "user_resetlink": `https://dev.sflhub.com/sflmw/ResetPassword/RVlFWVpJRUZRSw==`,
                // user_resetlink: location.pathname,
            }
            http
                .post(`api/v1/User/password/GetUserForLink`, body)
                .then(res => {
                    if (res.Success) {
                        setUserData(res.Value);
                        setOpen(false);
                    } else {
                        showErrorMessage(res.ResultMessage);
                        setOpen(false);
                    }
                })
                .catch(error => {
                    console.log(error)
                    showErrorMessage('Something went wrong');
                    setOpen(false);
                })
        }
        getUserData()
    }, [location])


    const checkPass = (e) => {
        setPassword(e.target.value)
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/.test(e.target.value)) {
            //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
            setPassStrength('Strong')
        } else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(e.target.value)) {
            // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
            setPassStrength('Good')
        } else if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(e.target.value)) {
            //Minimum eight characters, at least one letter and one number
            setPassStrength('Medium')
        } else if (e.target.value.length > 4) {
            //greater than 4
            setPassStrength('Weak')
        } else {
            setPassStrength('Too Short')
        }
    }

    const resetPass = () => {
        if (passStrength !== 'Strong') {
            showErrorMessage("Password criteria does not match")
        } else if (password !== confpassword) {
            showErrorMessage("Password does not match")
        } else if (userData.user_password === password) {
            showErrorMessage("You have entered old password")
        } else {
            const body = {
                "user_email": userData.user_email,
                "user_loginid": userData.user_loginid,
                // "user_resetlink": location.pathname,
                "user_resetlink": `${URL_UI}${ProjectName}${location.pathname}`,
                "user_password": password
            }
            http
                .post(`api/v1/User/password/reset`, body)
                .then(res => {
                    if (res.Success) {
                        showSuccessMessage(res.ResultMessage);
                        setOpen(false);
                        setTimeout(() => {
                            history.push(appRoute.LOGIN);
                        }, 3500)
                    } else {
                        showErrorMessage(res.ResultMessage);
                        setOpen(false);
                    }
                })
                .catch(error => {
                    console.log(error)
                    showErrorMessage('Something went wrong');
                    setOpen(false);
                })
        }
    }

    return (
        <div className='loginComponent container-fluid' style={{
            background: `url("/${ProjectName}/Assets/LoginBackground.png") 0% 0% / cover`
        }}>
            <div className='row'>
                <div className='col px-0 '>
                    <img src={`/${ProjectName}/Assets/Logo.svg`} alt='logo' className="logo" />
                    <div className='loginFormSection'>
                        <div className='loginDiv'>
                            <h5>Reset Password!</h5>
                            <div className="form-floating mb-3">
                                <input
                                    type={showPass ? 'text' : 'password'}
                                    className="form-control"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => { checkPass(e) }}
                                />
                                <label htmlFor="floatingPassword">Password</label>
                                {
                                    showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                }
                                {
                                    password &&
                                    <p className='showpassword passStrength text-end' style={{
                                        color:
                                            passStrength === 'Too Short' ? 'var(--bs-danger)' : passStrength === 'Weak' ? 'var(--bs-orange)' : passStrength === 'Medium' ? 'var(--bs-yellow)' : passStrength === 'Good' ? 'var(--bs-primary)' : 'var(--bs-success)'
                                    }}>{passStrength}</p>
                                }
                            </div>
                            {
                                (password && passStrength !== 'Strong') &&
                                <p className="error resetPass">Minimum 8 characters, atleast one special character, one uppercase, one lowercase letter and one number</p>
                            }
                            <div className="form-floating mb-3">
                                <input
                                    type={showConPass ? 'text' : 'password'}
                                    className="form-control"
                                    placeholder="Password"
                                    value={confpassword}
                                    onChange={(e) => { setConfPassword(e.target.value) }}
                                />
                                <label htmlFor="floatingPassword">Confirm Password</label>
                                {
                                    showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                                }
                            </div>
                            <button type="button" className="btn btn-lg" onClick={resetPass}>Reset Password</button>
                        </div>
                    </div>

                </div>
                {/* <div className='col-6 px-0 loginbannerSection'>

                </div> */}
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    )
}

export default ResetPassword
