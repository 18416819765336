import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import http from '../../api';
import { ProjectName } from '../../config';
import './../LoginComponent/login.css';

function ForgotPassword() {
    const [username, setUsername] = useState("");
    const [open, setOpen] = useState(false);
    const sendEmail = () => {
        if (!username) {
            NotificationManager.error("Please enter Email")
        } else if (!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(username))) {
            NotificationManager.error("Please enter valid Email")
        } else {
            setOpen(true);
            const body = {
                user_email: username
            };

            http
                .post(`api/v1/User/password/forgot`,
                    body).then((res) => {
                        setOpen(false);
                        if (res.Success) {
                            NotificationManager.success(res.ResultMessage, '', 3000);
                        } else {
                            NotificationManager.error(res.ResultMessage, '', 3000);
                        }
                    }).catch((error) => {
                        setOpen(false);
                        console.log(error)
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            error?.response?.status === 400 ?
                                NotificationManager.error(error?.response?.data?.error_description) : NotificationManager.error(error.response?.data?.Message);
                        } else {
                            console.log(error);
                            NotificationManager.error("Something went wrong!");
                        }
                    })
        }
    }

    return (
        <div className='loginComponent container-fluid' style={{
            background: `url("/${ProjectName}/Assets/LoginBackground.png") 0% 0% / cover`
        }}>
            <div className='row'>
                <div className='col px-0 '>
                    <img src={`/${ProjectName}/Assets/Logo.svg`} alt='logo' className='logo' />
                    <div className='loginFormSection'>
                        <div className='loginDiv'>
                            <h5>Forgot Password!</h5>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Enter your registered Email"
                                    value={username}
                                    onChange={(e) => { setUsername(e.target.value) }}

                                />
                                <label htmlFor="floatingInput">Enter your registered Email</label>
                            </div>
                            <button type="button" className="btn btn-lg" onClick={sendEmail}>Send Email</button>
                        </div>
                    </div>

                </div>
                {/* <div className='col-6 px-0 loginbannerSection'>
                    <img src='/charlesKendall/Assets/Logo.png' alt='logo' />
                </div> */}
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default ForgotPassword
