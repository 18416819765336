import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';

function ConnectionsEdit() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showConPass, setShowConPass] = useState(false);
    const [inputValues, setInputValues] = useState({
        connection_id: '',
        connection_name: '',
        connection_URL: '',
        connection_username: '',
        connection_password: '',
        connection_confPassword: ''
    });

    useEffect(() => {
        setLoading(true)
        setInputValues({
            ...inputValues,
            connection_id: history.location.state.connection_id,
            connection_name: history.location.state.connection_name,
            connection_URL: history.location.state.connection_URL,
            connection_username: history.location.state.connection_username,
            connection_password: history.location.state.connection_password,
            connection_confPassword: history.location.state.connection_password
        });
        setLoading(false);
    }, [history, inputValues])

    const editConn = () => {
        if (inputValues.connection_password === inputValues.connection_confPassword) {
            // let accessToken = JSON.parse(localStorage.userdata).access_token;
            const body = {
                "connection_id": inputValues.connection_id,
                "oper": "U",
                "connection_name": inputValues.connection_name,
                "connection_url": inputValues.connection_URL,
                "connection_username": inputValues.connection_username,
                "connection_password": inputValues.connection_password,
                "isactive": "Y",
                "createdby": 1
            };
            http.post(`api/v1/connection/post`,
                body)
                .then((res) => {
                    if (res.Success) {
                        NotificationManager.success(res.ResultMessage, '', 3000);
                        setTimeout(() => {
                            history.push(appRoute.Connections)
                        }, 3500);
                    } else {
                        NotificationManager.error(res.ResultMessage, '', 3000);
                    }
                }).catch((error) => {
                    console.log(error)
                    NotificationManager.error('Something went wrong', '', 3000);
                })
        } else {
            NotificationManager.error('Confirm Password does not match', '', 3000);
        }
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>Edit Connection</div>
            <div className='sorceDataDiv'>
                {
                    Object.keys(history.location.state).length !== 0 &&
                    <table className='source'>
                        <tbody>
                            <tr>
                                <th>Connection Name</th>
                                <th>:</th>
                                <td>
                                    <input type='text' value={inputValues.connection_name} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_name: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <th>Connection URL</th>
                                <th>:</th>
                                <td>
                                    <input type='text' value={inputValues.connection_URL} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_URL: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <th>User Name</th>
                                <th>:</th>
                                <td><input type='text' value={inputValues.connection_username} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_username: e.target.value })} /></td>
                            </tr>
                            <tr>
                                <th>Connection Password</th>
                                <th>:</th>
                                <td className='passTd'>
                                    <input type={showPass ? 'text' : 'password'} value={inputValues.connection_password} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_password: e.target.value })} />
                                    {
                                        showPass ? <VisibilityIcon onClick={() => setShowPass(false)} /> : <VisibilityOffIcon onClick={() => setShowPass(true)} />
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Confirm Password</th>
                                <th>:</th>
                                <td className='passTd'>
                                    <input type={showConPass ? 'text' : 'password'} value={inputValues.connection_confPassword} className='form-control showInputText' onChange={(e) => setInputValues({ ...inputValues, connection_confPassword: e.target.value })} />
                                    {
                                        showConPass ? <VisibilityIcon onClick={() => setShowConPass(false)} /> : <VisibilityOffIcon onClick={() => setShowConPass(true)} />
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                <Button variant='contained' className='editNsave' onClick={editConn}>Save</Button>
            </div>
        </div>
    )
}

export default ConnectionsEdit
