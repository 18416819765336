import React from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import './layout.css';
import { useHistory } from 'react-router-dom';

function Layout({ main, sidebarItemList }) {
    const history = useHistory();
    const closeSidebar = useSelector(state => state.reducer.closeSidebar);
    const goBack = () => {
        history.goBack();
    }
    return (
        <div className='layoutComponent'>
            <Header />
            <div className='sidebar_main'>
                <Sidebar sidebarItemList={sidebarItemList} />
                {main}
                <div className={closeSidebar ? 'goBack gobackLeft' : 'goBack gobackRight'} title="Back" onClick={goBack}><ArrowBackIcon /></div>
            </div>
        </div>
    )
}

export default Layout
