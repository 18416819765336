import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '300px', // Adjust width as needed
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      
    },
  };

const Batch = () => {
  const [batchData, setBatchData] = useState({
    BatchNumber: "290694",
    InvoiceNumber: 0,
    Process: "Process",
    Company: "Company"
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');

  const processOptions = ["Process", "NotaFiscal", "Dominio", "CFX", "Brazil Transactions"]; // Add more options as needed
  const companyOptions = ["Company", "BRA", "ASA", "BRB"]; // Add more options as needed

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://asprod.sflhub.com/MiddlewareAppsAPI/ExportBatch', batchData);
      console.log('Response:', response.data);
      setIsModalOpen(true); // Open the success modal
      setSelectedCompanyName(companyOptions.find(company => company === batchData.Company));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBatchData({
      ...batchData,
      [name]: value
    });
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the success modal
  };

  return (
    <div className='w-100 m-5'>
   <div className='container'>
   <form onSubmit={handleSubmit}>
    <div className='row'>
        <div className='col-lg-3'>
        <label>Batch Number:</label>
          <input
            type="text"
            name="BatchNumber"
            value={batchData.BatchNumber}
            onChange={handleInputChange}
            className='form-control'
          />
        </div>
        <div className='col-lg-3'>
        <label>Invoice Number:</label>
          <input
            type="number"
            name="InvoiceNumber"
            value={batchData.InvoiceNumber}
            onChange={handleInputChange}
            className='form-control'
          />
        </div>
        <div className='col-lg-3'>
        <label>Process:</label>
          <select
            name="Process"
            value={batchData.Process}
            onChange={handleInputChange}
            className='form-select'
          >
            {processOptions.map((process) => (
              <option key={process} value={process}>
                {process}
              </option>
            ))}
          </select>
        </div>
        <div className='col-lg-3'>
        <label>Company:</label>
          <select
            name="Company"
            value={batchData.Company}
            onChange={handleInputChange}
            className='form-select'
          >
            {companyOptions.map((company) => (
              <option key={company} value={company}>
                {company}
              </option>
            ))}
          </select>
        </div>

        <div className='col-lg-12 text-center mt-3'>  <button type="submit" className='btn btn-primary'>Submit</button></div>
    </div>
    
      </form>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Success Modal"
      >
        <h2 style={{color:'green'}}>Success!</h2>
        <p>Batch Exported for company. {selectedCompanyName}:{batchData.BatchNumber}</p>

        <button onClick={closeModal} className='btn btn-danger mt-3'>Close</button>
      </Modal>
    </div>
    </div>
  );
};

export default Batch;
