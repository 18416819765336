import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { Link, useLocation } from 'react-router-dom';
import appRoute from '../../../Route/appRoutes';
import http from '../../../api';
import './integrationDetails.css';

import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';


function ScheduleTimer() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    // const [edit, setEdit] = useState(false);
    const location = useLocation();
    const [integrationName, setIntegrationName] = useState('')
    const [integrationId, setIntegrationId] = useState(0);
    // const [startFrom, setStartFrom] = useState(new Date);
    // const [stopAt, setStopAt] = useState(new Date);
    const [status, setStatus] = useState(true);

    const edit = true;

    const [formData, setFormData] = useState({
        runat_every: '15',
        runat_time: 'm',
        status: true,
    });

    
    useEffect(() => {
        setIntegrationId(new URLSearchParams(location.search).get("integration_id"));
        setIntegrationName(new URLSearchParams(location.search).get("integration_name"));
    }, [location]);
    
    useEffect(() => {
        const getFrequency = () => {
            // const body = {
            // 	"integration_id": parseInt(new URLSearchParams(location.search).get("integration_id")),
            // }
            http.post(`/api/v1/Integration/Frequency/GET?integration_id=` + parseInt(new URLSearchParams(location.search).get("integration_id")) )
                .then((res) => {
                    const data = res?.Value ?? [];
                    setFormData({
                        runat_every: data.runat_every,
                        runat_time: data.runat_time,
                        status: data.status === '1' ? true : false,
                    });
                }).catch((error) => {
                    console.log(error)
                    NotificationManager.error('Something went wrong', '', 3000);
                })
        }
        getFrequency()
    }, [])

    const handleFormFieldsChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if ( ! formData.runat_every || ! formData.runat_time ) {
            NotificationManager.error('Please fill all fields', '', 3000);
			return false;
        }

        const body = {
            "integration_id": parseInt(integrationId),
            // "start_at": startFrom,
            // "stop_at": stopAt,
            "runat_every": formData.runat_every,
            "runat_time": formData.runat_time.toLowerCase(),
            "status": formData.status ? '1' : '0',
		};

		http.post(`/api/v1/Integration/Frequency/POST `, body)
			.then((res) => {
				if (res.Success) {
					NotificationManager.success(res.ResultMessage, '', 3000);
				} else {
					NotificationManager.error(res.ResultMessage, '', 3000);
				}
			}).catch((error) => {
				console.log(error)
				NotificationManager.error('Something went wrong', '', 3000);
			})
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}  >
            <div className='mainSectionHeading'>Timer</div>
            <div className='projectRoutes'> <Link to={appRoute.INTEGRATION}>INTEGRATIONS</Link> / {integrationName.toUpperCase()} </div>
            {/* <div className='mainSectionHeading'>Timer</div> */}
            <div className='sorceDataDiv'>
                <form onSubmit={handleSubmit}>
                    <table className='source'>
                        <tbody>
                            {/* <tr>
                                <th>Start From</th>
                                <th>:</th>
                                <td className='dateTimePickerTd'>
                                    <LocalizationProvider className='dateTimePickerElement' dateAdapter={AdapterDateFns}>
                                        <MobileDateTimePicker
                                            disabled={
                                                edit ? false : true
                                            }
                                            // disabled
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            value={startFrom}
                                            onChange={e => setStartFrom(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </td>
                            </tr>
                            <tr>
                                <th>Stop At</th>
                                <th>:</th>
                                <td className="dateTimePickerTd">
                                    <LocalizationProvider className='dateTimePickerElement' dateAdapter={AdapterDateFns}>
                                        <MobileDateTimePicker
                                            disabled={
                                                edit ? false : true
                                            }
                                            // disabled
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            value={stopAt}
                                            onChange={(e) => setStopAt(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </td>
                            </tr> */}
                            <tr>
                                <th>Frequency</th>
                                <th>:</th>
                                <td>Every <input type="number" name="runat_every" disabled={edit ? false : true} className={!edit ? 'form-control timer showInputText' : 'form-control timer'} value={formData.runat_every} step='5' min='10' max='20' onChange={handleFormFieldsChange} /> 
                                    <select name="runat_time" className="form-select w-auto d-inline mx-2" onChange={handleFormFieldsChange} value={formData.runat_time}>
                                        <option value="m">Minutes</option>
                                        <option value="s">Seconds</option>
                                    </select></td>
                            </tr>
                            <tr>
                                <th>Enabled</th>
                                <th>:</th>
                                <td><input type="checkbox" disabled={edit ? false : true} className='form-check-input' checked={status} onChange={(e) => setStatus(e.target.checked)} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <Button variant='contained' className='editNsave' type='submit'>Save</Button>
                </form>

                {/* {
                    edit ?
                        <Button variant='contained' className='editNsave' type='submit' onClick={() => { setEdit(edit => !edit); console.log(inputValues) }}>Save</Button>
                        :
                        <Button variant='contained' className='editNsave' type='submit' onClick={() => setEdit(edit => !edit)}>Edit</Button>
                }  */}
            </div>
        </div>
    )
}

export default ScheduleTimer
