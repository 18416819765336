import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import isLogin from '../utility/utils';
import appRoute from './appRoutes';

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const role = isLogin() ? JSON.parse(localStorage.userdata).role.toLowerCase() : '';

    // restricted = false meaning public route
    // restricted = true meaning restricted route
    return (
        <Route {...rest} render={props => {
            if (isLogin()) {
                if ( ! rest?.userAccess && role === 'user' ) {
                    return (<Redirect to={appRoute.DASHBOARD} />);
                } else {
                    return (<Component {...props} />);
                }
            } else {
                return (<Redirect to="/" />);
            }
        }} />
    );

}

export default ProtectedRoute